<template>
  <div class="position-relative" v-if="status">
    <div class="spinner-border text-danger position-absolute" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    status: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
};
</script>