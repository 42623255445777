<template>
  <div class="carList">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h2 class="underline mb-3" v-if="this.$route.name != 'Sales'">
            Seneste biler
          </h2>
        </div>
        <div class="col-12 col-sm-6 col-lg-3 card mb-4" v-for="car in sortedCars(cars)" :key="car.id">
          <Card :card="car" />
        </div>
          <div class="col-12 col-sm-6 col-lg-3 sold card mb-4"  v-for="car in sold(soldCars)" :key="car.id">
          <Card :card="car" :sold="true" />
          </div>
        <div class="col-12 text-center mt-4">
          <router-link :to="'/salgsliste'">
            <button
              class="btn btn-outline-secondary"
              v-if="this.$route.name != 'Sales'"
            >
              Se alle biler
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../components/Card";
import _ from "lodash";
export default {
  name: "CarList",
  props: {
    cars: {
      type: Array,
      required: true,
    },
    soldCars: {
      type: Array
    },
  },
  data() {
    return {
    };
  },
  methods: {
    sortedCars(cars) {
      return _.orderBy(_.orderBy(cars, "Model"), "Make", "asc");
    },
    sold(cars) {
      if(this.$route.name == 'Sales'){
        return cars.filter(item => item.DeletedDate)
    }}
  },
  components: {
    Card: Card,
  },
};
</script>