<template>
  <div class="partners mb-5">
    <div class="container">
      <div class="row text-center">
        <div class="col-12 mb-3"><h2 class="underline">{{heading}}</h2></div>
        <div class="col-3" v-for="partner in partnerImages" :key="partner.id">
            <img class="img-fluid" :src="partner.image"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: { type: String },
    partnerImages: { type: Array },
  },
  data() {
    return {};
  },

  components: {},
};
</script>