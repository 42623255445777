<template>
  <div class="home container-fluid" v-if="ready">
    <div class="row">
      <div class="col-12 p-0">
        <Slider
          v-if="content"
          :image="content.acf.slider.slider_img"
          :heading="content.acf.slider.slider_heading"
          :content="content.acf.slider.slider_content"
          :cta="content.acf.slider.cta"
        />
        <SellingPoints :sellingPoints="content.acf.sellingpoints" />
        <Partners
          :heading="content.acf.partners.heading"
          :partnerImages="content.acf.partners.partner"
        />
        <Spinner :status="spinner" />
        <CarList :cars="cars" />
      </div>
    </div>
  </div>
</template>
<script>
import axiosRequest from "../api/requestWrapper";
import Slider from "../components/Slider";
import SellingPoints from "../components/SellingPoints";
import CarList from "../components/CarList";
import Partners from "../components/Partners";
import Spinner from "../components/Spinner";
import axios from "axios";

export default {
  name: "Home",

  data() {
    return {
      cars: [],
      content: {},
      pageId: 7,
      ready: false,
      spinner: true,
    };
  },

  created() {
    this.render();
    var self = this;
    return axiosRequest(
      // "https://cors-anywhere.herokuapp.com/https://gw.bilinfo.net/listingapi/api/export/",
      "/cars.php",
      // "https://bilhusetroskilde.dk/cars.php",
      "GET"
    ).then((response) => {
      let reverseCars = response.data.Vehicles.reverse();
      self.cars = reverseCars.slice(0, 4);
      self.spinner = false;
    });
  },
  methods: {
    render() {
      axios({
        method: "get",
        url: "https://bilhusetroskilde.dk/content//wp-json/wp/v2/pages/" + this.pageId,
      }).then((response) => {
        this.content = response.data;
        this.ready = true;
        this.$store.dispatch("fetchPageContent", null);
      });
    },
  },
  watch: {},
  components: {
    Slider: Slider,
    SellingPoints: SellingPoints,
    Partners: Partners,
    CarList: CarList,
    Spinner: Spinner,
  },
};
</script>
