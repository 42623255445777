var equipmentListInDanish = [
    {name:"ABSBrakes", value: "ABS bremser"},
    {name:"Aircondition", value:"Aircondition"},
    {name:"AirconditionGlovebox" ,value: "Køl i handskerum"},
    {name:"Alarm" ,value:"Alarm"},
    {name:"AlloyRims" ,value:"Alufælge"},
    {name:"AntiSpin" ,value:"Antispin"},
    {name:"ArmRest" ,value:"Armlæn"},
    {name:"AutomaticClimateControl" ,value:"Automatisk klima"},
    {name:"AutomaticGear" ,value:"Automatgear"},
    {name:"AutomaticLight" ,value:"Automatisk lys"},
    {name:"Bluetooth" ,value:"Bluetooth"},
    {name:"CentralLocking" ,value:"Centrallås"},
    {name:"CruiseControl" ,value:"Fartpilot"},
    {name:"DualZoneClimateControl" ,value:"Dobbelzone klima"},
    {name:"FogLamps" ,value:"Tågelygter"},
    {name:"GearShiftStearingWheel" ,value:"Rat gearskifte"},
    {name:"GlassRoof" ,value:"Glastag"},
    {name:"HeightAdjustableFrontSeat" ,value:"Hj.justerbar forsæder"},
    {name:"Immobilizer" ,value:"Startspærre"},
    {name:"InfoCenter" ,value:"Infocenter"},
    {name:"Isofix" ,value:"Isofix"},
    {name:"LeatherSteeringWheel" ,value:"Læderrat"},
    {name:"LightWasher" ,value:"Lygtevasker"},
    {name:"LoweredSuspension" ,value:"Sænket undervogn"},
    {name:"Navigation" ,value:"Navigation"},
    {name:"ParkingSensor" ,value:"Parkeringssensor"},
    {name:"PoweredFrontSeats" ,value:"El-justerbar forsæder"},
    {name:"PoweredMirrors" ,value:"El-sidespejle"},
    {name:"PoweredMirrorsHeated" ,value:"El-sidespejle m. varme"},
    {name:"PoweredSunRoof" ,value:"El-soltag"},
    {name:"PoweredWindows" ,value:"Elruder"},
    {name:"PowerWindows4" ,value:"Elruder, 4x"},
    {name:"RearViewAutoDimming" ,value:"Bakspejl m. nedbl."},
    {name:"RemoteCentralLocking" ,value:"Fjb. centrallås"},
    {name:"RemovableTowbar" ,value:"Aft. anhængertræk"},
    {name:"RoofRails" ,value:"Tagskinner"},
    {name:"SeatHeater" ,value:"Sædevarme"},
    {name:"ServiceOK" ,value:"Service OK"},
    {name:"Servo" ,value:"Servo"},
    {name:"SplitBackSeat" ,value:"Split bagsæde"},
    {name:"Spoiler" ,value:"Spoiler"},
    {name:"SunRoof" ,value:"Soltag"},
    {name:"TaintedWindows" ,value:"Tonede ruder"},
    {name:"Towbar" ,value:"Anhængertræk"},
    {name:"TripComputer" ,value:"Kørecomputer"},
    {name:"VelourSeats" ,value:"Veloursæder"},
    {name:"XenonLight" ,value:"Xenon lygter"},
    {name:"HeatedSteeringWheel" ,value:"Varme i rat"},
    {name:"ClimateControl3Zone" ,value:"Klimaanlæg, 3-zonet"},
    {name:"ClimateControl4Zone" ,value:"Klimaanlæg, 4-zonet"},
    {name:"CDplayer" ,value:"CD afspiller"},
    {name:"DABRadio" ,value:"DAB radio"},
    {name:"DABPlusRadio" ,value:"DAB plus radio"},
    {name:"ThreeSixtyCamera" ,value:"360 graders kamera"},
    {name:"LEDDrivingLights" ,value:"LED kørelygter"},
    {name:"FullLEDHeadlights" ,value:"Fuld led lygter"},
    {name:"CabinHeater" ,value:"Kabinevarmer"},
    {name:"ElectricParkingBrake" ,value:"El. håndbremse"},
    {name:"SwingAwayTowbar" ,value:"Svingbar anhængertræk"},
    {name:"SwingAwayTowbarElectric" ,value:"El. svingbar anhængertræk"},
    {name:"DigitalCockpit" ,value:"Digital cockpit"},
    {name:"WirelessCellphoneCharging" ,value:"Trådløs tlf. opladning"},
    {name:"ParkingSensorFront" ,value:"P.sensor for"},
    {name:"ParkingSensorBack" ,value:"P.sensor bag"},
    {name:"ElectricFoldableSideMirrors" ,value:"El foldbare sidespejle"},
    {name:"ElectricFoldableSideMirrorsHeated" ,value:"El foldbare sidespejle m.varme"},
    {name:"UsedCarCertificate" ,value:"Brugtbilsattest"},
    {name:"BluetoothAudioStreaming" ,value:"Bluetooth musik"},
    {name:"BiXenon" ,value:"BiXenon"},
    {name:"ImitationLeather" ,value:"Kunstlæder"},
    {name:"LeatherSeats" ,value:"Sædebetræk, læder"},
    {name:"FabricSeats" ,value:"Sædebetræk, stof"},
    {name:"SportSeats" ,value:"Sportsæder"},
    {name:"SDCardReader" ,value:"SD-kort læser"},
    {name:"USBConnection" ,value:"USB"},
    {name:"AUXConnection" ,value:"AUX"},
    {name:"IntegratedChildSeats" ,value:"Integreret barnesæde"},
    {name:"ElectricAdjustableDriversSeathWithMemory" ,value:"El justerbar føresæde med memory"},
    {name:"FatigueDetection" ,value:"Trætheds detektion"},
    {name:"TrafficSignRecognition" ,value:"Skilt genkendelse"},
    {name:"HeightAdjustableDriversSeat" ,value:"Højde justerbar føresæde"},
    {name:"ThreeIndividualseatsinback" ,value:"Individuelle bagsæder"}]
    export default equipmentListInDanish;


// var equipmentListInDanish = [{
// ABSBrakes: "ABS bremser",
// Aircondition: "Aircondition",
// AirconditionGlovebox: "Køl i handskerum",
// Alarm: "Alarm",
// AlloyRims: "Alu fælge",
// AntiSpin: "Antispin",
// ArmRest: "Armlæn",
// AutomaticClimateControl: "Automatisk klima",
// AutomaticGear: "Automatgear",
// Bluetooth: "Bluetooth",
// CentralLocking: "Centrallås",
// CruiseControl: "Fartpilot",
// DualZoneClimateControl: "Dobbelzone klima",
// FogLamps: "Tågelygter",
// GearShiftStearingWheel: "Rat gearskifte",
// GlassRoof: "Glastag",
// HeightAdjustableFrontSeat: "Højdejusterbar forsæder",
// Immobilizer: "Startspærre",
// InfoCenter: "Infocenter",
// Isofix: "Isofix",
// LeatherSteeringWheel: "Læderrat",
// LightWasher: "Lygtevasker",
// LoweredSuspension: "Sænket undervogn",
// Navigation: "Navigation",
// ParkingSensor: "Parkeringssensor",
// PoweredFrontSeats: "El-justerbar forsæder",
// PoweredMirrors: "El-sidespejle",
// PoweredMirrorsHeated: "El-sidespejle m. varme",
// PoweredSunRoof: "El-soltag",
// PoweredWindows: "El-ruder",
// PowerWindows4: "El-ruder alle 4",
// RearViewAutoDimming: "Nedblændeligt bakspejl",
// RemoteCentralLocking: "Fjernbetjent centrallås",
// RemovableTowbar: "Aft. anhængertræk",
// RoofRails: "Tagskinner",
// SeatHeater: "Sædevarme",
// ServiceOK: "Service OK",
// Servo: "Servo",
// SplitBackSeat: "Split bagsæde",
// Spoiler: "Spoiler",
// SunRoof: "Soltag",
// TintedWindows: "Tonede ruder",
// Towbar: "Anhængertræk",
// TripComputer: "Kørecomputer",
// VelourSeats: "Veloursæder",
// XenonLight: "Xenon lygter",
// HeatedSteeringWheel: "Varme i rat",
// ClimateControl3Zone: "3-zonede klima",
// ClimateControl4Zone: "4-zonede klima",
// CDplayer: "CD afspiller",
// DABRadio: "DAB radio",
// DABPlusRadio: "DAB plus radio",
// ThreeSixtyCamera: "360 graders kamera",
// LEDDrivingLights: "LED kørelygter",
// FullLEDHeadlights: "Fuld led lygter",
// CabinHeater: "Kabinevarmer",
// ElectricParkingBrake: "El. parkeringsbremse",
// SwingAwayTowbar: "Svingbar anhængertræk",
// SwingAwayTowbarElectric: "El. svingbar anhængertræk",
// DigitalCockpit: "Digital cockpit",
// WirelessCellphoneCharging: "Trådløs tlf. opladning",
// ParkingSensorFront: "P.sensor foran",
// ParkingSensorBack: "P. sensor bagved",
// ElectricFoldableSideMirrors: "El foldbare sidespejle",
// ElectricFoldableSideMirrorsHeated: "El foldbare sidespejle m.varme",
// UsedCarCertificate: "Brugtbilsattest",
// BluetoothAudioStreaming: "Bluetooth musik",
// BiXenon: "BiXenon",
// ImitationLeather: "Kunstig læder",
// SportSeats: "Sportsæder",
// SDCardReader: "SD-kort læser",
// USBConnection: "USB",
// AUXConnection: "AUX",
// IntegratedChildSeats: "Integreret barnesæde",
// ElectricAdjustableDriversSeathWithMemory: "El justerbar føresæde med memory",
// FatigueDetection: "Trætheds detektion",
// TrafficSignRecognition: "Skilt genkendelse",
// HeightAdjustableDriversSeat: "Højde justerbar føresæde",
// ThreeIndividualseatsinback: "Individuelle bagsæder"}]
// export default equipmentListInDanish;