<template>
  <div class="mainHeader">
    <nav class="navbar navbar-expand-lg navbar-light container">
      <router-link to="/">
      <a class="navbar-brand d-inline-block align-top"><img :src="logo.url"/></a>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" v-for="page in pages" :key="page.id">
            <router-link v-on:click.native="updatePageId(page.slug)" :to="page.slug == 'forside' ? '/' : `/${page.slug}`" exact class="nav-link" >
              {{page.title.rendered}}
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>


<script>
export default {
  props: {
    pages: {
      type: Array
    },
    logo: {
      type: Object
    },
  },
  data() {
    return {};
  },
  methods: {
    updatePageId(pageId) {
      this.$store.dispatch("fetchChangePageId", pageId);
    },
  },
};
</script>