import Vue from "vue"
import moment from "moment"

Vue.filter("toKilometer", function (value) {
    var formatter = new Intl.NumberFormat('da-DK', {
        style: 'decimal',
        unit: 'kilometer',
    });
    return formatter.format(value);
});

Vue.filter('toCurrency', function (value) {
    var formatter = new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: 'DKK',
        minimumFractionDigits: 0
    });
    return formatter.format(value);
});

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});

