import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        pageId: "forside",
        pageContent: []
    },

    getters: {},

    actions: {
        fetchChangePageId({ commit }, value) {
            commit('setPageId', value);
        },
        fetchPageContent({ commit }, value) {
            commit('setPageContent', value);
        }
    },

    mutations: {
        setPageId: (state, payload) => {
            state.pageId = payload;
        },
        setPageContent: (state, payload) => {
            state.pageContent = payload;
        },
    },
    plugins: [createPersistedState()]
});
