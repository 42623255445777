<template>
  <div class="sales mt-3">
    <div class="container">
      <h1>Salgsliste</h1>
      <ContactFormWrapper />
      <Spinner :status="spinner" />
    </div>
    <CarList :cars="cars" :soldCars="soldCars" />
  </div>
</template>

<script>
import axiosRequest from "../api/requestWrapper";
import axios from "axios";
import CarList from "../components/CarList";
import Spinner from "../components/Spinner";
import ContactFormWrapper from "../components/ContactFormWrapper";
export default {
  name: "Sales",

  data() {
    return {
      cars: [],
      soldCars: [],
      spinner: true,
    };
  },
  created() {
    Promise.all([this.getActiveCars(), this.getSoldCars()]).then(
      axios.spread((getActiveCars, getSoldCars) => {
        this.cars = getActiveCars.data.Vehicles;
        this.soldCars = getSoldCars.data.Vehicles;
        this.spinner = false;
      })
    );
  },

  methods: {
    getActiveCars() {
      return axiosRequest(
        // "https://cors-anywhere.herokuapp.com/https://gw.bilinfo.net/listingapi/api/export/",
        // "https://bilhusetroskilde.dk/cars.php",
        "/cars.php",
        "GET"
      );
    },
    getSoldCars() {
      return axiosRequest(
        // "https://cors-anywhere.herokuapp.com/https://gw.bilinfo.net/listingapi/api/export?sinceDays=14",
        // "https://bilhusetroskilde.dk/soldcars.php",
        "/soldcars.php",
        "GET"
      );
    },

    async render() {
      axios({
        method: "get",
        url:
          // "http://rabiler.dk.linux308.unoeuro-server.com/content//wp-json/wp/v2/pages?slug=" +
          "https://bilhusetroskilde.dk/content//wp-json/wp/v2/pages?slug=" +

          this.$route.params.pageName,
      }).then((response) => {
        this.$store.dispatch("fetchPageContent", response.data[0]);
      });
    },
  },

  components: {
    CarList: CarList,
    Spinner: Spinner,
    ContactFormWrapper: ContactFormWrapper,
  },
};
</script>
