<template>
  <div>
    <Spinner :status="spinner" />
    <div class="container car mt-md-4" v-if="render">
      <div class="row mb-4">
        <div class="col-12 col-xl-8 p-0">
          <VueperSlides
            @slide="
              $refs.vueperslides2.goToSlide($event.currentSlide.index, {
                emit: false,
              })
            "
            ref="vueperslides1"
          >
            <VueperSlide
              v-for="slide in car.Pictures"
              :key="slide.id"
              :image="slide"
            />
          </VueperSlides>

          <VueperSlides
            class="no-shadow thumbnails"
            ref="vueperslides2"
            :slide-ratio="1 / car.Pictures.length"
            :dragging-distance="50"
            @slide="
              $refs.vueperslides1 &&
                $refs.vueperslides1.goToSlide($event.currentSlide.index, {
                  emit: false,
                })
            "
            :visible-slides="5"
            :bullets="false"
            :touchable="false"
            :gap="2.5"
            :arrows="false"
          >
            <VueperSlide
              v-for="(slide, i) in car.Pictures"
              :key="i"
              :image="slide"
              @click.native="$refs.vueperslides2.goToSlide(i)"
            >
            </VueperSlide>
          </VueperSlides>
        </div>
        <div class="col-12 col-xl-4 p-3 px-md-4 pt-md-4 carSidebar">
          <div class="carMakeModelVariant text-center mb-3">
            <h2>{{ car.Make + " " + car.Model }}</h2>
            <p>{{ car.Variant }}</p>
          </div>
          <div class="carPrice text-center mb-3">
            <div class="row">
              <div class="col-6"><h5>Kontantpris</h5></div>

              <div class="col-6">
                <h5 class="mb-3">{{ car.Price | toCurrency }}</h5>
              </div>
            </div>
          </div>
          <div class="carQuickDetails">
            <div class="row">
              <div class="col-6">
                <p>
                  <i class="bticon bticon-GroupAgeAndState"></i>
                  {{ car.Mileage | toKilometer }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <i class="bticon bticon-FirstRegistrationDate"></i>
                  {{ car.Year }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <i class="bticon bticon-GroupSecurityAndEconomy"></i>
                  {{ car.KmPerLiter }} km/l
                </p>
              </div>
              <div class="col-6">
                <p>
                  <i class="bticon bticon-PropellantType"></i>
                  {{ car.Propellant }}
                </p>
              </div>
            </div>
          </div>
          <div class="carCTAs mb-3">
            <div class="row">
              <div class="col-12 mb-3">
                <button
                  class="btn btn-secondary w-100"
                  @click="handleModals"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  id="bookTestDrive"
                >
                  <i class="bticon bticon-TestDrive"></i>Book prøvetur
                </button>
              </div>
              <!-- <div class="col-6">
                <button
                  class="btn btn-outline-secondary w-100"
                  @click="handleModals"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  id="bookFinancing"
                >
                  <i class="bticon bticon-GroupFinances"></i>Finansiering
                </button>
              </div> -->
              <div class="col-12">
                <button
                  type="button"
                  class="btn btn-outline-secondary w-100"
                  @click="handleModals"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  id="bookCallMe"
                >
                  <i class="bticon bticon-CallBack"></i>Ring mig op
                </button>
              </div>
            </div>
          </div>
          <div class="carContact">
            <p class="mb-0">
              {{ car.DealerAddressStreetLine1 }}<br />
              {{ car.DealerAddressZipCode + " " + car.DealerAddressCity }}<br />
              <a :href="`tel:${car.DealerPhoneNumber}`">
                <i class="fas fa-phone-alt mr-2"></i>
                {{ car.DealerPhoneNumber }} </a
              ><br />
              <a :href="`mailto:${car.DealerEmail}`">
                <i class="far fa-envelope mr-2"></i>
                {{ car.DealerEmail }}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="row carDetails">
        <div class="col-xl-8">
          <div class="carComments mb-4">
            <h3 class="underline-left">Beskrivelse</h3>
            <p>{{ car.Comment }}</p>
          </div>
          <div class="carEquipment mb-4">
            <h3 class="underline-left">Udstyr</h3>
            <ul class="row" v-if="danishEquipments.length && car.EquipmentList.length">
              <li
                class="col-12 col-md-6 col-lg-4 equipment"
                v-for="equipment in car.EquipmentList"
                :key="equipment.id"
              >
                <i class="bticon bticon-Check mr-2"></i
                >{{ handleEquipments(equipment, danishEquipments) }}
              </li>
            </ul>
          </div>
          <div class="carSpecifications mb-4">
            <h3 class="underline-left mb-4">Specifikationer</h3>
            <h5>Alder og kilometerstand</h5>
            <ul class="row">
              <li class="col-12 col-md-6 col-lg-4 specification">
                Kilometer<span>{{ car.Mileage }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Model<span>{{ car.Year }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                1. indregistreringsdato<span>{{ car.RegistrationDate }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Sidst synet<span>{{ car.InspectionDate }}</span>
              </li>
            </ul>

            <h5>Motor og ydelse</h5>
            <ul class="row">
              <li class="col-12 col-md-6 col-lg-4 specification">
                0-100<span>{{ car.Acceleration0To100 }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Antal cylindre<span>{{ car.Cylinders }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Antal gear<span>{{ car.NumberOfGears }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Gear<span>{{
                  car.GearType == "A" ? "Automatisk" : "Manuel"
                }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Drivmiddel<span>{{ car.Propellant }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Maksimal moment<span>{{ car.EffectInNm }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Maksimal effekt<span>{{ car.Effect }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Motor størrelse<span>{{ car.Motor }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Top hastighed<span>{{ car.TopSpeed }}</span>
              </li>
            </ul>

            <h5>Sikkerhed og økonomi</h5>
            <ul class="row">
              <li class="col-12 col-md-6 col-lg-4 specification">
                Antal airbags<span>{{ car.NumberOfAirbags }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Km/L<span>{{ car.KmPerLiter }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Ejerafgift<span>{{ car.GreenTax }}</span>
              </li>
            </ul>

            <h5>Rummelighed og mål</h5>
            <ul class="row">
              <li class="col-12 col-md-6 col-lg-4 specification">
                Karosseri<span>{{ car.BodyType }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Farve<span>{{ car.Color }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Antal døre<span>{{ car.NumberOfDoors }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Bredde<span>{{ car.Width }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Højde<span>{{ car.Height }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Længde<span>{{ car.Length }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Vægt<span>{{ car.Weight }}</span>
              </li>
              <li class="col-12 col-md-6 col-lg-4 specification">
                Tankkapacitet<span>{{ car.GasTankMax }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2251.683110754852!2d12.11297254325963!3d55.64232675106089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46525fb10888c14b%3A0x7f5e81de14ac980!2sK%C3%B8benhavnsvej%20102%2C%204000%20Roskilde!5e0!3m2!1sda!2sdk!4v1724962701606!5m2!1sda!2sdk"
            width="100%"
            height="200"
            frameborder="0"
            style="border: 0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </div>
      <ContactFormWrapper :car="this.car" />
    </div>
  </div>
</template>

<script>
import axiosRequest from "../api/requestWrapper";
import { eventBus } from "../main";
import ContactFormWrapper from "./ContactFormWrapper";
import Spinner from "./Spinner";
import { VueperSlides, VueperSlide } from "vueperslides";
import equipmentListInDanish from "../equipmentList";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "Car",
  data() {
    return {
      car: {},
      render: false,
      spinner: true,
      danishEquipments: [],
    };
  },

  created() {
    this.danishEquipments = equipmentListInDanish;
    return axiosRequest(
      // "https://cors-anywhere.herokuapp.com/https://gw.bilinfo.net/listingapi/api/export",
      // "https://thingproxy.freeboard.io/fetch/https://gw.bilinfo.net/listingapi/api/export",
      // "https://gw.bilinfo.net/listingapi/api/export",
      // "https://bilhusetroskilde.dk/cars.php",
      "/cars.php",
      
      "GET"
    ).then((response) => {
      let cars = response.data.Vehicles;
      this.car = cars.find((item) => item.Id === this.$route.params.id);
      this.spinner = false;
      this.render = true;
    });
  },

  methods: {
    handleModals() {
      eventBus.$emit("ctaClicked", event.target.id);
    },
    handleEquipments(nameKey, array) {
      let danishVersion;
      danishVersion =  array.find(x => x.name === nameKey);
      if (danishVersion !== undefined){
        return danishVersion.value
      }
    },
  },
  computed: {},
  components: { VueperSlides, VueperSlide, ContactFormWrapper, Spinner },
};
</script>
