<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ formHeading }}</h5>
        </div>
        <div class="modal-body">
          <CallMeForm
            v-if="this.contact.formId == 'bookCallMe'"
            @submittedCallMeForm="callMeFormData"
            :car="this.$props.car"
          />
          <BookTestDriveForm
            v-if="this.contact.formId == 'bookTestDrive'"
            @submittedTestDriveForm="TestDriveFormData"
            :car="this.$props.car"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CallMeForm from "./CallMeForm";
import BookTestDriveForm from "./BookTestDriveForm";
import { eventBus } from "../main";

export default {
  props: {
    car: {
      type: Object,
    },
  },
  data() {
    return {
      contact: {
        formId: "",
        from: "",
        subject: "",
        body: "",
      },
    };
  },

  created() {
    eventBus.$on("ctaClicked", (data) => {
      this.contact.formId = data;
    });
  },

  methods: {
    callMeFormData(params) {
      this.contact.from = params.email;
      this.contact.subject = params.id;

      this.contact.body =
        `${params.intro}` +
        "<br><br>" +
        `Fornavn: ${params.name}` +
        "<br>" +
        `Efternavn: ${params.lastName}` +
        "<br>" +
        `Email: ${params.email}` +
        "<br>" +
        `Telefon: ${params.telephone}` +
        "<br>" +
        `Henvendelsen drejer om: 
                  ${
                    this.$props.car.Make +
                    this.$props.car.Model +
                    "<br>" +
                    window.location.href
                  }`;
      this.sendEmail();
    },

    TestDriveFormData(params) {
      this.contact.from = params.email;
      this.contact.subject = params.id;
      this.contact.body =
        `${params.intro}` +
        "<br><br>" +
        `Fornavn: ${params.name}` +
        "<br>" +
        `Efternavn: ${params.lastName}` +
        "<br>" +
        `Email: ${params.email}` +
        "<br>" +
        `Telefon: ${params.telephone}` +
        "<br>" +
        `Dato (år/måned/dag): ${params.date}` +
        "<br>" +
        `Tidspunkt: ${params.time}` +
        "<br>" +
        `Henvendelsen drejer om: 
                  ${
                    this.$props.car.Make +
                    " " +
                    this.$props.car.Model +
                    ", " +
                    this.$props.car.Variant +
                    "<br>" +
                    window.location.href
                  }`;
      this.sendEmail();
    },

    sendEmail() {
      window.Email.send({
        SecureToken: "24e5243b-2b5f-4903-8ff7-fcd94bdfc579",
        To: "salg@bilhusetroskilde.dk",
        From: "zubonet2@gmail.com",
        Subject: this.contact.subject,
        Body: this.contact.body,
      });
    },
  },
  computed: {
    formHeading: function () {
      let formHeading =
        this.contact.formId == "bookCallMe"
          ? "Ring mig op"
          : this.contact.formId == "bookTestDrive"
          ? "Book prøvetur"
          : this.contact.formId == "bookFinancing"
          ? "Finansiering"
          : "";
      return formHeading;
    },
  },
  components: {
    CallMeForm: CallMeForm,
    BookTestDriveForm: BookTestDriveForm,
  },
};
</script>
