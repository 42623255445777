import axios from 'axios'

// Create an Axios Client with defaults
const client = axios.create({
    'Content-Type': 'application/json'});


// Add a request interceptor
client.interceptors.request.use(function (config) {
    var encodedString = btoa("RA-Biler12824:Ridwan00@@@");
    config.headers["Authorization"] = `Basic ${encodedString}`;
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Methods"] = "GET, POST, PATCH, PUT, DELETE, OPTIONS";
    config.headers["Access-Control-Allow-Headers"] = "Origin, Content-Type, X-Auth-Token";
    return config;
});

// Request Wrapper with default success/error actions
const axiosRequest = function (url, type) {
    const options = {
        url: url,
        method: type,
    }
    const onSuccess = function (response) {
        // console.debug('Request Successful!', response);
        return response;
    }

    const onError = function (error) {
        console.error('Request Failed:', error.config);

        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);

        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    }
    return client(options)
        .then(onSuccess)
        .catch(onError);
}

export default axiosRequest;
