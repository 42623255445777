<template>
  <div class="footer mt-5 py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6 companyInfo">
          <img class="footerLogo" :src="footerLogo.sizes.medium" />
          <p>{{ contact.name }}</p>
          <p>{{ contact.adress }}</p>
          <p>{{ contact.zipAndCity }}</p>
          <p>{{ contact.cvr }}</p>
        </div>
        <div class="col-12 col-sm-6">
          <h5>{{ openingHours.heading }}</h5>
          <div class="openingHours">
            <div
              class="openingHour"
              v-for="siker in openingHours.openingHour"
              :key="siker.id"
            >
              <span>{{ siker.dato + ": " }}</span>
              <span>{{ siker.period }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Footer",
  props: {
    contact: { type: Object },
    openingHours: { type: Object },
    footerLogo: { type: Object },
    email: { type: String },
  },
  data() {
    return {};
  }
};
</script>