import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Sales from '../views/Sales'
import Car from '../components/Car'



Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props:true,
  },
  {
    path: '/salgsliste/:make/:model/:variant/:id',
    name: 'Car',
    component: Car,
    props:true,
  },
  {
    path: '/salgsliste/:make/:model/:id',
    name: 'Car',
    component: Car,
    props:true,
  },
  {
    path: '/salgsliste',
    name: 'Sales',
    component: Sales,
    props:true,
  },
  {
    path: '/:pageName',
    name: 'SubPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
