<template>
  <div class="contactForm">
    <form id="callMeForm" onsubmit="event.preventDefault();">
      <div class="row">
        <div class="col-12 mb-3 form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Fornavn"
            required
            v-model="form.name"
          />
          <div class="invalid-feedback">Ugyldig indtastning!</div>
        </div>
        <div class="col-12 mb-3 form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Eternavn"
            required
            v-model="form.lastName"
          />
          <div class="invalid-feedback">Ugyldig indtastning!</div>
        </div>
        <div class="col-12 mb-3 form-group">
          <input
            type="email"
            class="form-control"
            placeholder="E-mail"
            required
            v-model="form.email"
          />
          <div class="invalid-feedback">Ugyldig indtastning!</div>
        </div>
        <div class="col-12 mb-3 form-group">
          <input
            type="tel"
            class="form-control"
            placeholder="Telefon"
            required
            v-model="form.telephone"
          />
          <div class="invalid-feedback">Ugyldig indtastning!</div>
        </div>
        <div class="col-12 mb-3 form-group">
          <input
            type="text"
            class="form-control"
            readonly
            :value="this.form.carDetail"
          />
        </div>
        <div
          v-if="this.form.success"
          class="alert alert-success form-group w-100 mx-3"
          role="alert"
        >
          <div>Vi har modtaget din henvendelse!</div>
        </div>
      </div>
      <button @click="sendToWrapper()" class="btn btn-primary mr-3">
        Ring mig op
      </button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        Luk
      </button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    car: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        id: "En kunde vil ringes op - Bilhusetroskilde.dk",
        intro:
          "Hej Bilhuset Roskilde, en kunde vil ringes op. Oplysninger findes nedenfor",
        name: "",
        lastName: "",
        email: "",
        telephone: "",
        carDetail: `${
          this.$props.car.Make +
          " " +
          this.$props.car.Model +
          ", " +
          this.$props.car.Variant
        }`,
        success: false,
      },
    };
  },

  methods: {
    sendToWrapper() {
      let form = document.getElementById("callMeForm");
      if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        this.$emit("submittedCallMeForm", this.form);
        this.form.success = true;

        var self = this;
        setTimeout(function () {
          form.classList.remove("was-validated");
          self.form.success = false;
          self.formReset();
        }, 5000);
      }
      form.classList.add("was-validated");
    },
    formReset() {
      this.form.name = "",
      this.form.lastName = "",
      this.form.email = "",
      this.form.telephone = ""
    },
  },
};
</script>
