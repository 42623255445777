<template>
  <div class="secondFooter py-2">
    <div class="container text-center">
      <div class="row">
        <div class="col-12">
          <span>© {{companyName}} {{ new Date().getFullYear() }}</span>
          <span>{{companyCvr}}</span>
          <router-link :to="'/privatlivspolitik'">
            <span class="cookieLink">Cookie- og privatlivspolitik</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "secondFooter",
  props: {
    companyName: { type: String },
    companyCvr: { type: String }
  },
  data() {
    return {};
  },
};
</script>
