<template>
  <div id="app" v-if="render">
    <TopHeader :telephone="settings.telephone" :email="settings.email" />
    <Header :pages="pages" :logo="settings.logo" />
    <router-view />
    <Footer
      :contact="settings.contact"
      :openingHours="settings.openingHours"
      :footerLogo="settings.white_logo"
      :email="settings.email"
      :telephone="settings.telephone"
    />
    <SecondFooter
      :companyName="settings.contact.name"
      :companyCvr="settings.contact.cvr"
    />
  </div>
</template>

<script>
import axios from "axios";
import Header from "./components/Header";
import TopHeader from "./components/TopHeader";
import Footer from "./components/Footer";
import SecondFooter from "./components/SecondFooter";

export default {
  data() {
    return {
      pages: [],
      settings: [],
      render: false,
    };
  },

  mounted() {
    const weply = document.createElement("script");
    weply.setAttribute("src", "https://app.weply.chat/widget/f960bcc5cd4d22a85d67dabf9211cdde");
    weply.async = true;
    document.head.appendChild(weply);
  },

  created() {
    Promise.all([this.getSettings(), this.getPages()]).then(
      axios.spread((getSettings, getPages) => {
        this.settings = getSettings.data.acf;
        let sortedByMenuOrder = [...getPages.data].sort(
          (a, b) => a.menu_order - b.menu_order
        );
        this.pages = sortedByMenuOrder.filter(function (element) {
          return element.slug !== "privatlivspolitik";
        });
        this.render = true;
      })
    );
  },
  methods: {
    getPages() {
      return axios({
        method: "get",
        url: "https://bilhusetroskilde.dk/content//wp-json/wp/v2/pages",
      });
    },
    getSettings() {
      return axios({
        method: "get",
        url: "https://bilhusetroskilde.dk/content//wp-json/acf/v3/options/settings",
      });
    },
  },
  components: {
    Header: Header,
    TopHeader: TopHeader,
    Footer: Footer,
    SecondFooter: SecondFooter,
  },
};
</script>
