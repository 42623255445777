<template>
  <div class="topHeader">
    <div class="container">
      <div class="topHeaderContent">
        <span class="mr-5" v-if="email">
          <a :href="`mailto:${email}`">
            <i class="far fa-envelope mr-2"></i>
            {{email}}
          </a>
        </span>
        <span v-if="telephone">
          <a :href="`tel:${telephone}`">
            <i class="fas fa-phone-alt mr-2"></i>
            {{telephone}}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    email: {
      type: String,
      required: true
    },
    telephone: {
      type: String,
      required: true
    },
  },
  data() {
    return {};
  },
};
</script>