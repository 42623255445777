<template>
  <div class="slider" :style="{ backgroundImage: `url('${image}')` }">
    <div class="container text-align-left text-left">
      <h1>{{heading}}</h1>
      <h3 v-html="content"></h3>
      <router-link :to="'/salgsliste'">
        <button class="btn btn-secondary mr-4" href="/salgsliste">{{cta.button_text}}</button>
      </router-link>
      <!-- <button class="btn btn-outline-primary">Bliv ringet op</button> -->
    </div>
  </div>

  <!-- <ul id="example-1">
    <li v-for="car in cars.Vehicles" :key="car.id">{{ car.Make }}</li>
  </ul>-->
</template>

<script>
export default {
  props: {
    image: { type: String },
    heading: { type: String },
    content: { type: String },
    cta: { type: Object }
  },
  data() {
    return {};
  },

  components: {},
};
</script>