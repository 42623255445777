<template>
  <div>
    <router-link :to="`${sold ? '' : 'salgsliste/' + cleanUrl}`">
      <div class="cardThumb">
        <span v-if="sold" class="sold">Solgt</span>
        <span v-if="newOrNot(card.CreatedDate) && !sold" class="newCar">Nyhed</span>
        <img :src="card.Pictures[0]" class="img-fluid" />
      </div>
      <div class="details p-3">
        <h5 class="makeModel text-center mb-0">
          {{ card.Make + " " + card.Model }}
        </h5>
        <p class="variant text-center">{{ card.Variant }}</p>
      </div>
      <h5 class="text-center cardPrice py-2">{{ card.Price | toCurrency }}</h5>
      <div class="details p-3">
        <div class="cardParams cardYear row">
          <div class="col-12 py-2">
            <div class="cardParamLabel float-left">Årgang</div>
            <div class="cardParamValue float-right">{{ card.Year }}</div>
          </div>
        </div>
        <div class="cardParams cardMileage row">
          <div class="col-12 py-2">
            <div class="cardParamLabel float-left">Kilometer</div>
            <div class="cardParamValue float-right">
              {{ card.Mileage | toKilometer }}
            </div>
          </div>
        </div>
        <div class="cardParams cardPropellant row">
          <div class="col-12 py-2">
            <div class="cardParamLabel float-left">Drivmiddel</div>
            <div class="cardParamValue float-right">{{ card.Propellant }}</div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Car",
  props: {
    card: {
      type: Object,
      required: true,
    },
    sold: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    newOrNot(createdDate) {
      let carsDate = moment(createdDate, "DD-MM-YYYY[T]HH:mm:ss");
      let today = moment();

      let days = today.diff(carsDate, "days");

      if (days > 14) {
        return false;
      } else {
        return true;
      }
    },
  },
  computed: {
    cleanUrl: function () {
      let variant = this.$props.card.Variant
      let cleanVariant = variant.split('/').join('-'); // g - replace all
      let url = this.$props.card.Variant != "" ? `${this.$props.card.Make}/${this.$props.card.Model}/${cleanVariant}/${this.$props.card.Id}` : `${this.$props.card.Make}/${this.$props.card.Model}/${this.$props.card.Id}`;
      return url.replace(/ /g, "-"); // g - replace all
    },
  },
};
</script>