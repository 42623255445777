<template>
  <div class="sellingPoints text-center mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6 sellingPoint" v-for="sellingPoint in sellingPoints" :key="sellingPoint.id" :style="{ backgroundColor: `${sellingPoint.background}` }">
          <h2>{{sellingPoint.heading}}</h2>
          <p v-html="sellingPoint.content"></p>
          <!-- <a :href="sellingPoint.cta_link">
            <button class="btn btn-secondary">{{sellingPoint.cta_text}}</button>
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sellingPoints: { type: Object },
  },
  data() {
    return {};
  },

  components: {},
};
</script>